<template>
  <v-card class="pa-3 delivery-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Delivery address</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="detail.deliveryAddress.name"
          label="Name"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.deliveryAddress.address"
          label="Address"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.deliveryAddress.additionalAddress"
          label="Add. addr."
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="detail.deliveryAddress.zipcode"
          label="Zipcode"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.deliveryAddress.city"
          label="City"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="detail.deliveryAddress.country"
          :items="dataStore.originCountries"
          hide-details
          dense
          label="Country"
          outlined
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.deliveryAddress.eoriNumber"
          dense
          label="EORI number"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.deliveryAddress.contact"
          dense
          label="Contact"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.deliveryAddress.contactPhone"
          dense
          label="Phone number"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.deliveryAddress.contactEmail"
          dense
          label="E-mail"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.deliveryAddress.contactFax"
          dense
          label="Fax"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="detail.deliveryAddress.remark"
          label="Operational remarks"
          outlined
          hide-details
          auto-grow
          row-height="15"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const dataStore = useDataStore();

const props = defineProps<IProps>();
const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");
</script>
