import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class FilterParcels extends Vue {
  @Prop({ default: () => [] })
  value!: string[];

  singleParcelText = "";
  multiParcelsText = "";
  showMultiParcelsFilterDialog = false;
  currentMultiParcels = "";

  get multiParcels() {
    return this.multiParcelsText?.trim()
      ? this.multiParcelsText
          .trim()
          .split("\n")
          .filter((p: string) => p)
          .map((item) => item.trim())
          .filter((item) => item)
      : [];
  }

  get singleParcel() {
    return this.singleParcelText;
  }
  set singleParcel(value: string) {
    value = value.trim();
    this.singleParcelText = value;
    this.filterConfirmed();
  }

  @Watch("value", { immediate: true })
  onValueChanged(newVal: any, oldVal: any) {
    if (!newVal) {
      this.currentMultiParcels = "";
      this.singleParcelText = "";
    }
  }

  onShowMultiParcelsFilterDialog() {
    this.currentMultiParcels = this.multiParcelsText;
    this.showMultiParcelsFilterDialog = true;
  }

  onCancelMultiParcelsFilterDialog() {
    this.multiParcelsText = this.currentMultiParcels;
    this.currentMultiParcels = "";
    this.showMultiParcelsFilterDialog = false;
  }

  onConfirmMultiParcelsFilterDialog() {
    this.showMultiParcelsFilterDialog = false;
    this.filterConfirmed();
  }

  clearSingle() {
    this.singleParcelText = "";
    this.filterConfirmed();
  }

  filterConfirmed() {
    let parcels = this.multiParcels?.length ? [...this.multiParcels] : [];
    if (this.singleParcelText) {
      parcels.push(this.singleParcelText);
    }
    this.$emit("input", parcels);
  }
}
