<template>
  <v-card class="pa-3 invoice-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Invoice address</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.name"
          label="Name"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.address"
          label="Address"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.invoiceAddress.additionalAddress"
          label="Add. addr."
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="detail.invoiceAddress.zipcode"
          label="Zipcode"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.invoiceAddress.city"
          label="City"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="detail.invoiceAddress.country"
          :items="dataStore.originCountries"
          item-text="description"
          item-value="code"
          hide-details
          dense
          label="Country"
          outlined
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.invoiceAddress.eoriNumber"
          dense
          label="EORI number"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.invoiceAddress.niederlassungsNummer"
          label="Niederlassungsnummer"
          dense
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <!-- TODO CHECK -->
        <v-textarea
          v-model="detail.invoiceAddress.operational"
          label="Operational remarks"
          outlined
          hide-details
          auto-grow
          row-height="15"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="detail.invoiceAddress.remark"
          label="Invoicing remarks"
          outlined
          hide-details
          auto-grow
          row-height="15"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <div class="checkbox-container">
          <v-row gutter="20">
            <v-checkbox
              v-model="detail.invoiceAddress.declarantIsConsignee"
              hide-details
              label="Declarant is consignee"
            ></v-checkbox>
            <v-checkbox
              v-model="detail.invoiceAddress.customerWithCredit"
              hide-details
              label="Customer with credit"
            ></v-checkbox>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const dataStore = useDataStore();

const props = defineProps<IProps>();
const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");
</script>
