<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-3 order-details-card">
      <v-toolbar class="elevation-0" dense>
        <v-card-title>Order / details</v-card-title>
      </v-toolbar>
      <v-row>
        <div class="checkbox-container">
          <v-col cols="12">
            <v-row>
              <v-checkbox
                hide-details
                label="EZA"
                v-model="detail.orderDetails.eza"
              ></v-checkbox>
              <v-checkbox
                hide-details
                label="Transit T1"
                v-model="detail.orderDetails.transitT1"
              ></v-checkbox>
              <v-checkbox
                hide-details
                label="Clear. by customs"
                v-model="detail.orderDetails.clearanceByCustomer"
              ></v-checkbox>
              <v-checkbox
                hide-details
                label="Charges to sender"
                v-model="detail.orderDetails.chargesToSender"
              ></v-checkbox>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-checkbox
                hide-details
                label="Returned goods"
                v-model="detail.orderDetails.returnedGoods"
              ></v-checkbox>
              <v-checkbox
                hide-details
                label="Sonderauslagerung"
                v-model="detail.orderDetails.sonderauslagerung"
              ></v-checkbox>
              <!-- TODO CHECK -->
              <v-checkbox hide-details label="Is export"></v-checkbox>
            </v-row>
          </v-col>
        </div>
        <v-col cols="8">
          <v-select
            v-model="detail.orderDetails.customerId"
            :items="dataStore.generalCustomers"
            item-text="shortName"
            item-value="id"
            hide-details
            dense
            label="customer"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary">Pre-alert</v-btn>
        </v-col>
        <v-col cols="8">
          <ValidationProvider
            name="Days without clearance"
            rules="min_value:1"
            v-slot="{ errors }"
            immediate
          >
            <!-- TODO CHECK -->
            <v-text-field
              v-model="placeHolderModel"
              hide-details
              type="number"
              dense
              label="Days without clearance"
              outlined
              :error-messages="errors"
            ></v-text-field>
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-card>
  </validation-observer>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}

const dataStore = useDataStore();

const props = defineProps<IProps>();
const dateModel = ref<string | null>(null);
const placeHolderModel = ref("");
</script>
