<template>
  <v-card class="pa-3 shipment-info-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Shipment information</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.pieces"
          label="Pieces"
          outlined
          type="number"
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="detail.shipmentInformation.value"
          step="0.01"
          type="number"
          label="Value"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="detail.shipmentInformation.currency"
          :items="dataStore.currencies"
          item-text="name"
          item-value="id"
          hide-details
          dense
          label="Currency"
          outlined
        ></v-select>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.realWeight"
          label="Real weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.shipmentInformation.incoterms"
          :items="dataStore.incoterms"
          item-text="name"
          item-value="id"
          hide-details
          dense
          label="Incoterms"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.shipmentInformation.volumeWeight"
          label="Volume weight"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn
          @click="shipmentDimensionsDialog = true"
          color="primary"
          class="ml-3"
          >D</v-btn
        >
      </v-col>
      <v-col cols="4">
        <!-- TODO CHECK -->
        <v-text-field
          v-model="detail.shipmentInformation.calculatedVolumeWeight"
          hide-details
          dense
          label="Calc. vol. weight"
          outlined
        ></v-text-field>
      </v-col>
      <v-dialog v-model="shipmentDimensionsDialog" width="660px"
        ><ShipmentInfoDimensionsDialog
          :detail="detail"
          :open-dialog.sync="shipmentDimensionsDialog"
        ></ShipmentInfoDimensionsDialog>
      </v-dialog>
      <v-col cols="6">
        <v-btn @click="openParcels()" color="primary">Parcels</v-btn>
      </v-col>
      <v-dialog v-model="parcelsDialog" width="1200px">
        <ShipmentInfoParcelsDialog
          :key="timeStampKey"
          :detail.sync="detail"
          :save="detail"
          :open-dialog.sync="parcelsDialog"
        ></ShipmentInfoParcelsDialog>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useDataStore } from "@/stores/data-store";
import ShipmentInfoDimensionsDialog from "./dialogs/ShipmentInfoDimensionsDialog.vue";
import ShipmentInfoParcelsDialog from "./dialogs/ShipmentInfoParcelsDialog.vue";
import { ShipmentDetailViewModel } from "@/openapi";

const dataStore = useDataStore();

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const timeStampKey = ref(Date.now());

const shipmentDimensionsDialog = ref<boolean>(false);
const parcelsDialog = ref<boolean>(false);

const openParcels = () => {
  timeStampKey.value = Date.now();
  parcelsDialog.value = true;
};
</script>
